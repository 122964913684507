<template>
  <div class="page-block-item">
    <div v-show="loaded" class="page-block-item">
      <div v-if="isClientAuthTypeEmail"
          class="dataentrydiv restaurant-selection margin-bottom-50">

        <div class="input-label margin-bottom-25">
          {{ getUploadCovidTestPage_enterNameAndRestaurantText() }}
        </div>

        <InputUserName v-model="userName" />

        <SelectableRestaurant
          v-model="selectedRestaurant"
          :userId="loggedUser.id"
          @completed="selectableRestaurantLoaded = $event"/>

      </div>

      <div class="input-container">
        <div class="dataentrydiv input-container-left">
          <div class="general-text text-justify margin-bottom-15">
            {{ $t('uploadCovidTestPage_selectCovidTestDate') }}
          </div>
          <div class="margin-bottom-25">
            <DatePicker class="datepick"
              v-model="covidTestDate"
              :isDateDisabled="isFutureDate" />
          </div>
        </div>

        <div class="dataentrydiv input-container-right">
          <div class="general-text margin-bottom-15"
               v-html="$t('uploadCovidTestPage_selectCovidTestResult')">
          </div>
          <div >
            <SelectableTestResult
                class="datepick"
                v-model="covidTestResult" />
          </div>
        </div>
      </div>

      <div v-if="false" class="dataentrydiv input-contact-row margin-bottom-25">
        <div class="general-text text-justify margin-bottom-5">
          {{ $t('uploadCovidTestPage_contactEmailInfo') }}
        </div>
        <div class="input-contact-right">
          <input class="w-input"
                type="email"
                v-model="contactEmail"
                :placeholder="$t('uploadCovidTestPage_contactEmailPlaceholder')" />
        </div>
      </div>

      <div class="dataentrydiv margin-bottom-25">
        <div class="input-label margin-bottom-25">
          {{ getUploadTextObject().title }}
        </div>
        <div v-if="getUploadTextObject().extraText1">
          <p>{{ getUploadTextObject().extraText1 }}</p>
        </div>
        <div v-if="getUploadTextObject().extraText2">
          <p>{{ getUploadTextObject().extraText2 }}</p>
        </div>
        <div v-if="getUploadTextObject().extraText3">
          <p>{{ getUploadTextObject().extraText3 }}
            <a href="https://www.flynnrestaurantgroup.com/workplace-privacy-notice/" target="_blank">https://www.flynnrestaurantgroup.com/workplace-privacy-notice/</a>
          </p>
        </div>

        <vue-dropzone ref="uploadVueDropzone" id="dropzoneId"
          :options="dropzoneOptions" :useCustomSlot="true"
          @vdropzone-file-added="fileAdded"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-sending="uploadingImage"
          @vdropzone-error="errorUploading"
          @vdropzone-queue-complete="uploadCompleted">
          <div class="dropzone-custom-content">
            <img src="@/assets/images/upload.svg" loading="lazy" alt="" class="uploadicon">
            <div>{{ $t('uploadAreaInfo') }}</div>
          </div>
        </vue-dropzone>
      </div>

      <div v-if="clientId === 'sodexo'" class="page-block-item bodytext general-text text-justify">
        <p> {{ $t('disclaimerSodexo') }} </p>
      </div>

      <div class="dataentrydiv margin-bottom-25">
        <span v-if="!submitting" @click="submitForm" class="button w-button">
          {{ $t('uploadCovidTestPage_submitLabel') }}
        </span>
        <span v-else class="button w-button">
          <div class="lds-loader"><div></div><div></div><div></div></div>
        </span>
      </div>
      <div class="dataentrydiv">
        <privacy-policy />
      </div>
    </div>

    <modal height="auto" name="extraCovidTestInputModal">
      <p class="page-title">{{ $t('uploadCovidTestPage_moreInfo') }}</p>
      <p class="general-text">{{ $t('uploadCovidTestPage_moreInfoDesc') }}</p>
      <p class="general-text">{{ $t('uploadCovidTestPage_moreInfoBestContact' )}}</p>
      <p class="general-text">{{ $t('uploadCovidTestPage_moreInfoBestContactDesc') }}</p>

      <div class="dataentrydiv margin-bottom-15">
        <input class="w-input"
                  type="email"
                  v-model="contactEmail"
                  :placeholder="$t('uploadCovidTestPage_contactEmailPlaceholder')" />
      </div>
      <div class="dataentrydiv margin-bottom-15">
        <input class="w-input"
                  type="tel"
                  v-model="phoneNumber"
                  :placeholder="$t('uploadCovidTestPage_contactPhoneNumberPlaceholder')" />
      </div>

      <span style="width: 100%"
          @click="hideModal"
          class="button w-button">
        {{ $t('saveYourChanges') }}
      </span>
    </modal>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { uuid } from 'vue-uuid';
import Path from 'path';

import AuthApi from '@/api/auth.api';
import EmployeeApi from '@/api/employee.api';
import UploadApi from '@/api/upload.api';
import Utils from '@/utils/utils';

import InputUserName from '@/components/common/user/InputUserName.vue';
import SelectableRestaurant from '@/components/common/user/SelectableRestaurant.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import SelectableTestResult from '@/components/covid-test/SelectableTestResult.vue';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const CLIENT = Utils.getClient();

function validateEmail(mail) {
  if (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/.test(mail)) {
    return (true);
  }
  return (false);
}

export default {
  name: 'UploadCovidTest',
  props: ['value', 'loggedUser', 'clientAuthType'],

  components: {
    InputUserName,
    SelectableRestaurant,
    DatePicker,
    SelectableTestResult,
    vueDropzone,
  },

  data() {
    return {
      selectedEmployee: null,
      selectedRestaurant: null,
      covidTestDate: '',
      covidTestResult: '',
      clientId: CLIENT,
      contactEmail: '',
      phoneNumber: '',
      dropzoneOptions: {
        url: UploadApi.getCovidTestEndpoint(),
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: false,
        addRemoveLinks: true,
        withCredentials: true,
        headers: {
          'x-sub-client': CLIENT,
        },
      },
      uploadedFiles: [],
      uploadHasError: false,
      modalImgURL: '',
      submitting: false,
      selectableRestaurantLoaded: false,
      userName: {
        firstName: '',
        lastName: '',
      },
      requestContantOnUpload: false,
    };
  },

  created() {
    this.selectedEmployee = this.value;
  },

  methods: {
    resetForm() {
      this.covidTestDate = '';
      this.covidTestResult = '';
      this.contactEmail = '';
      this.phoneNumber = '';
      this.uploadedFiles = [];
      this.userName.firstName = '';
      this.userName.lastName = '';
      this.selectedRestaurant = null;
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    fileAdded() {
      const existingFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      Object.keys(existingFiles).forEach((key) => {
        this.$refs.uploadVueDropzone.removeFile(existingFiles[key]);
      });
    },

    fileRemoved() {
      this.uploadedFiles = [];
    },

    uploadingImage(file, xhr, formData) {
      const fileName = `${uuid.v1()}${Path.extname(file.name)}`;
      formData.append('client', CLIENT);
      formData.append('employeeId', this.selectedEmployee);
      formData.append('fileName', fileName);
      formData.append('testDateMetadata', this.covidTestDate);
      this.uploadedFiles.push(fileName);
    },

    errorUploading() {
      this.uploadHasError = true;
      this.uploadedFiles = [];
      this.$refs.uploadVueDropzone.removeAllFiles();
    },

    uploadCompleted() {
      if (!this.uploadHasError) {
        // Complete sending data
        this.completeSubmitForm();
      } else {
        this.submitting = false;
        // Display error message
        this.showErroAlert({
          title: this.$t('uploadError'),
          text: this.$t('uploadErrorInst'),
        });
      }
    },

    submitForm() {
      this.hideModal();
      this.submitting = true;
      this.uploadHasError = false;

      if (!this.validateForm()) {
        this.submitting = false;
        return;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (queuedFiles.length) {
        this.$refs.uploadVueDropzone.processQueue();
      } else {
        // Complete sending data
        this.completeSubmitForm();
      }
    },

    async completeSubmitForm() {
      const covidTestRecord = {
        clientId: CLIENT,
        employeeId: this.selectedEmployee,
        testDate: this.covidTestDate,
        testResult: this.covidTestResult.value,
        contactEmail: this.contactEmail,
        phoneNumber: this.phoneNumber,
        images: [...this.uploadedFiles],
      };

      if (this.isClientAuthTypeEmail) {
        covidTestRecord.firstName = this.userName.firstName;
        covidTestRecord.lastName = this.userName.lastName;
        covidTestRecord.restaurantId = this.selectedRestaurant.value;
      }

      try {
        await EmployeeApi.submitCovidTest(this.selectedEmployee, covidTestRecord);
        await this.showSuccesslert({
          title: this.$t('success'),
          text: this.$t('uploadCovidTestPage_submitSuccess'),
        });

        this.$emit('submitted');
      } catch (e) {
        if (e.response?.data?.code === 'NO_WELLNESS_SURVEY') {
          this.requestContantOnUpload = true;
          this.showModal();
        } else {
          await this.showErroAlert({
            title: 'Oops..',
            text: this.$t('somethingWentWrong'),
          });
        }
      }
      this.submitting = false;
    },

    validateForm() {
      if (this.isClientAuthTypeEmail) {
        if (!this.userName.firstName) {
          this.showWarninglert({
            title: this.$t('uploadCovidTestPage_firstNameMissingTitle'),
            text: this.$t('uploadCovidTestPage_firstNameMissingText'),
          });
          return false;
        }

        if (!this.userName.lastName) {
          this.showWarninglert({
            title: this.$t('uploadCovidTestPage_lastNameMissingTitle'),
            text: this.$t('uploadCovidTestPage_lastNameMissingText'),
          });
          return false;
        }

        if (!this.selectedRestaurant) {
          this.showWarninglert({
            title: this.$t('uploadCovidTestPage_restaurantMissingTitle'),
            text: this.$t('uploadCovidTestPage_restaurantMissingText'),
          });
          return false;
        }
      }

      if (!this.covidTestDate) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('uploadCovidTestPage_pleaseSelectCovidTestDate'),
        });
        return false;
      }

      if (!this.covidTestResult) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('uploadCovidTestPage_pleaseSelectCovidTestResult'),
        });
        return false;
      }

      if (this.covidTestResult.value === 'POSITIVE' && this.requestContantOnUpload) {
        if (!this.contactEmail) {
          this.showModal();
          return false;
        }

        if (!validateEmail(this.contactEmail)) {
          this.showModal();
          this.showWarninglert({
            title: this.$t('validationError'),
            text: this.$t('uploadCovidTestPage_informContactEmail'),
          });
          return false;
        }
      } else {
        this.contactEmail = '';
        this.phoneNumber = '';
      }

      if (this.contactEmail && !validateEmail(this.contactEmail)) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('uploadCovidTestPage_informContactEmail'),
        });
        return false;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (this.uploadedFiles.length === 0 && queuedFiles.length === 0) {
        this.showWarninglert({
          title: this.$t('uploadCovidTestPage_missingCard'),
          text: this.$t('uploadCovidTestPage_mustUpload'),
        });
        return false;
      }
      return true;
    },

    getUploadTextObject() {
      const uploadText = {
        title: this.$t('uploadCovidTestPage_uploadCovidTestCopyTitle'),
      };

      if (CLIENT === 'bloomin') {
        uploadText.title = this.$t('uploadCovidTestPage_uploadCovidTestCopyBelowTitle');
      }

      if (CLIENT === 'flynnrg') {
        uploadText.extraText1 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg1');
        uploadText.extraText2 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg2');
        uploadText.extraText3 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg3');
      }

      return uploadText;
    },

    getUploadCovidTestPage_enterNameAndRestaurantText() {
      if (CLIENT === 'blazepizza'
          || CLIENT === 'centerplate'
          || CLIENT === 'saucepizzaandwine') {
        return this.$t('uploadCovidTestPage_enterNameAndLocationText');
      }
      if (CLIENT === 'windham') {
        return this.$t('uploadCovidTestPage_enterNameAndDepartmentText');
      }
      return this.$t('uploadCovidTestPage_enterNameAndRestaurantText');
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },

    showErroAlert(message) {
      return this.showAlert({
        ...message,
        icon: 'error',
      });
    },

    showWarninglert(message) {
      return this.showAlert({
        ...message,
        icon: 'warning',
      });
    },

    showSuccesslert(message) {
      return this.showAlert({
        ...message,
        icon: 'success',
      });
    },

    showAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    showModal() {
      if (this.isClientAuthTypeEmail && !this.contactEmail) {
        this.contactEmail = this.selectedEmployee;
      }

      this.$modal.show('extraCovidTestInputModal');
    },

    hideModal() {
      this.$modal.hide('extraCovidTestInputModal');
    },
  },

  computed: {
    isClientAuthTypeEmail() {
      return this.clientAuthType === 'EMAIL';
    },

    loaded() {
      const isLoaded = !this.isClientAuthTypeEmail || this.selectableRestaurantLoaded;
      this.$emit('completed', isLoaded);
      return isLoaded;
    },
  },

  watch: {
    selectedEmployee() {
      this.resetForm();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
  .dz-progress {
    display: none !important;
  }

  /* Date Pick CSS */
  .vdpComponent {
    width: 350px;
  }

  .vdpComponent.vdpWithInput  > button {
    border: 1px solid#e8e8e8;
    border-radius: 8px;
  }

  .vdpCell.today {
    color: red;
  }

  .vm--modal {
    width: 600px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    border: 1px solid #5031D0;
    padding: 25px;
  }

  .multiselect__tags {
    font-size: 16px !important;
    border: 1px solid #cccccc;
  }

  @media screen and (max-width: 479px) {
    .vm--modal {
      width: 300px !important;
      height: auto !important;
      left: calc(100% - 479px + 30%) !important;
    }

    .vdpComponent {
      width: 100%;
    }
  }

</style>
<style scoped>
  /* Submit loader */

  .lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    border: 1px solid #5031D0;
  }
  .lds-loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  /* Vue Dropzone CSS */
  .vue-dropzone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #97D4FF;
    border-radius: 10px;
    background-color: #E8F8FF;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #4D497E;
  }

  .vue-dropzone:hover {
    background-color: rgba(63, 119, 225, 0.28);
  }
  /* Page CSS */

  .nav-buttons {
    padding-top: 0px !important;
  }

  .navigation-button {
    width: 250px;
    margin: 0 7px;
  }

  .wellness-menu-button, .wellness-menu-button:hover {
    border: 0px;
    color: #ffffff;
    background-color: #000000
  }

  .dashboard-menu-button {
    background-color: #ffffff;
    border: 1px solid #aaadb0;
    color: #aaadb0;
  }

  .dashboard-menu-button:hover {
    color: #757575;
  }

  .uploadicon {
    width: 40px;
    margin-bottom: 15px;
  }

  .herolist {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .list-item {
    margin-bottom: 15px;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .daterow-nav-buttons {
    justify-content: center;
  }

  .restaurant-selection input {
    border-radius: 4px;
  }

  .restaurant-selection > .daterow > .multiselect {
    margin: 0 auto;
  }

  .first-name-input {
    margin-right: 10px;
  }

  .last-name-input {
    margin-left: 10px;
  }

  .datepick {
    width: 100%;
  }

  .delete-btn {
    width: 80px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
  }

  .dropzone-custom-content {
    line-height: 27px;
  }

  .input-container {
    display: flex;
  }

  .input-container-left {
    width: 100%;
  }

  .input-container-left > div,
  .input-container-right > div {
    width: 100%;
  }

  .input-container-right {
    width: 500px;
    margin-left: 25px;
  }

  @media screen and (max-width: 479px) {
    /* Vue Dropzone CSS */
    .vue-dropzone  {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      padding: 0px;
    }

    /* Multi Select CSS */
    .multiselect {
      width: 100%;
      min-width: 0px;
    }

    /* Page CSS */
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .daterow .datepicker-btn {
      margin-top: 15px;
    }

    .datepick {
      width: 100%;
    }

    .first-name-input {
      margin-right: 0px;
    }

    .last-name-input {
      margin-left: 0px;
    }

    .navigation-button {
      width: 100%;
      margin: 0;
    }

    .dashboard-menu-button {
      margin-top: 20px !important;
    }

    .input-container {
      display: block;
    }
    .input-container-right {
      width: 100%;
      margin-left: 0px;
    }

  }

</style>
