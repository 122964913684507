<template>
  <div class="page-block">

    <i18n-selector />

    <span @click="logout" class="button logoutbutton w-inline-block underline-link"
      v-if="clientAuthType !== 'SSO'">
      <img src="@/assets/images/logout-1.svg" loading="lazy" alt="" class="logouticon">
      <div>{{ $t('dashboardPage_logout') }}</div>
    </span>

    <zhh-zedic-header :title="$t('uploadCovidTestPage_covidTestRecordTitle')" />

    <MenuNavigation v-if="!showPageSpinningLoader && loggedUser"
      :loggedUser="loggedUser"
      :hasMultipleEmployees="hasMultipleEmployees"
      :page="'UploadCovidTestPage'" />

    <div v-if="clientId === 'sodexo' && showPageDescription"
         class="page-block-item bodytext general-text text-justify">
      <p> {{ $t('uploadCovidTestPage_pageAdvisorySodexo') }} </p>
    </div>

    <div v-if="showPageDescription" class="page-block-item bodytext general-text text-justify">
      {{ getPageDescription() }}
    </div>

    <spinning-loader v-show="showPageSpinningLoader" />

    <div class="page-container" v-show="!showPageSpinningLoader">
      <SelectableUser
        v-model="selectedEmployee"
        :clientAuthType="clientAuthType"
        @completed="
          selectableUserLoaded = $event.loaded;
          hasMultipleEmployees = $event.multipleEmployees;
        "/>

      <WrongUser v-if="selectedEmployee"
        :userName="loggedUser.name"
        :clientAuthType="clientAuthType"
        :displayAdminWarning="hasMultipleEmployees" />

      <spinning-loader v-if="!uploadCovidTestLoaded && selectedEmployee" />

      <UploadCovidTest v-if="selectedEmployee && loggedUser"
        v-model="selectedEmployee.value"
        :key="selectedEmployee.value"
        :loggedUser="loggedUser"
        :clientAuthType="clientAuthType"
        @completed="uploadCovidTestLoaded = $event"
        @submitted="covidTestUploaded" />

    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import MenuNavigation from '@/components/common/user/MenuNavigation.vue';
import SelectableUser from '@/components/common/user/SelectableUser.vue';
import WrongUser from '@/components/common/user/WrongUser.vue';
import UploadCovidTest from '@/components/covid-test/UploadCovidTest.vue';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'UploadCovidTestPage',
  components: {
    MenuNavigation, SelectableUser, WrongUser, UploadCovidTest,
  },

  data() {
    return {
      loggedUser: undefined,
      clientAuthType: undefined,
      loadingPage: true,
      selectedEmployee: undefined,
      selectableUserLoaded: false,
      uploadCovidTestLoaded: false,
      hasMultipleEmployees: false,
      clientId: CLIENT,
    };
  },

  created() {
    if (this.isHepProgram()) {
      this.$router.push({ name: 'AuthErrorPage' });
    } else {
      this.fetchLoggedUser();
    }
  },

  methods: {
    isHepProgram() {
      return Utils.isHepProgramClient();
    },

    async fetchLoggedUser() {
      try {
        const loggedUserResult = await AuthApi.getMe();
        this.loggedUser = loggedUserResult.data;
        const { roles = [], features = [] } = this.loggedUser;

        if (!roles.includes('USER') || !features.includes('COVID_TEST_TRACKING')) {
          this.$router.push({ name: 'AuthErrorPage' });
          return;
        }

        this.clientAuthType = loggedUserResult.data.auth;
        this.loadingPage = false;
      } catch (e) {
        console.error(e);
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
      }
    },

    covidTestUploaded() {
      this.$router.push({ name: 'UploadVaccinationPage', params: { colapseInput: true, selectedEmployee: this.selectedEmployee } });
    },

    showErroAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    getPageDescription() {
      if (this.loggedUser.roles.includes('MANAGER')) {
        return this.$t('uploadCovidTestPage_managerPageDescription');
      }
      return this.$t('uploadCovidTestPage_pageDescription');
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },

  computed: {
    showPageDescription() {
      return this.selectableUserLoaded;
    },

    showPageSpinningLoader() {
      return this.loadingPage || !this.selectableUserLoaded;
    },
  },
};
</script>
<style scoped>
  .logoutbutton {
    width: 190px;
    color: #5031D0;
    text-decoration: none;
    position: absolute;
    right: 5%;
    margin-top: -25px;
    text-transform: uppercase;
  }

  .logouticon {
    width: 25px;
    margin-right: 12px;
  }

  @media screen and (max-width: 479px) {
    .page-container {
      width: 100%;
    }

    .logoutbutton {
      position: relative;
      right: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
</style>
