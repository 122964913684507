<template>
  <multiselect v-model="selectedTestResult" :options="testResultOptions"
    label="label" track-by="label"
    :searchable="true" :allow-empty="false" :close-on-select="true"
    :show-labels="false"
    :placeholder="$t('selectResult')">
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

function mapToSelect(label, value) {
  return { label, value };
}

export default {
  name: 'SelectableTestResult',
  components: {
    Multiselect,
  },
  props: ['value'],

  data() {
    return {
      selectedTestResult: undefined,
    };
  },

  computed: {
    testResultOptions() {
      return [
        mapToSelect(this.$t('selectResultPositive'), 'POSITIVE'),
        mapToSelect(this.$t('selectResultNegative'), 'NEGATIVE'),
      ];
    },
  },

  watch: {
    selectedTestResult() {
      this.$emit('input', this.selectedTestResult);
    },
  },
};
</script>
<style scoped>

</style>
