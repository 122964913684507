<template>
  <div v-show="loaded" class="page-block-item">
    <div v-if="employeeOptions.length != 1" class="dataentrydiv margin-bottom-25">
      <div class="label margin-bottom-5">{{ $t('selectEmployeeLabel') }}</div>
      <multiselect v-model="selectedEmployee" :options="employeeOptions" label="label"
        track-by="label" :searchable="true" :allow-empty="false"
        :close-on-select="true" :show-labels="false"
        :placeholder="$t('selectEmployeePlaceHolder')">
      </multiselect>
    </div>
    <div v-else class="dataentrydiv">
      <div v-if="isClientAuthTypeEmail" class="label margin-bottom-5">
        {{ $t('employeeEmailLabel') }}
      </div>
      <div v-else class="label margin-bottom-5">{{ $t('employeeNameAndID') }}</div>
      <div>
        <span class="multiselect__single margin-bottom-0 employee-label">
          {{ selectedEmployee.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import EmployeeApi from '@/api/employee.api';

import Multiselect from 'vue-multiselect';

function mapToSelect(label, value) {
  return { label, value };
}

export default {
  name: 'SelectableUser',
  components: {
    Multiselect,
  },
  props: ['value', 'clientAuthType'],

  data() {
    return {
      loaded: false,
      employees: [],
      employeeOptions: [],
      selectedEmployee: undefined,
    };
  },

  async created() {
    try {
      const employeesResult = await EmployeeApi.getAll();
      this.employees = employeesResult.data;
      if (this.isClientAuthTypeEmail) {
        this.employeeOptions = this.employees
          .map((employee) => mapToSelect(`${employee.name}`, employee.id));
      } else {
        this.employeeOptions = this.employees
          .map((employee) => mapToSelect(`${employee.name} ${employee.id}`, employee.id));
      }

      if (this.employeeOptions.length === 1) {
        [this.selectedEmployee] = this.employeeOptions;
      }
      this.$emit('completed', {
        loaded: true,
        multipleEmployees: (this.employeeOptions.length > 1),
      });
    } catch (e) {
      //
    }
    this.loaded = true;
  },

  methods: {
    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },

  computed: {
    isClientAuthTypeSSO() {
      return this.clientAuthType === 'SSO';
    },

    isClientAuthTypeEmail() {
      return this.clientAuthType === 'EMAIL';
    },
  },

  watch: {
    selectedEmployee() {
      this.$emit('input', this.selectedEmployee);
    },
  },
};
</script>
<style scoped>

  .employee-label {
    font-size: 18px;
    line-height: 21px;
  }

  .multiselect {
    width: 100%;
    min-width: 800px;
    font-weight: 400;
  }

  @media screen and (max-width: 479px) {
    /* Multi Select CSS */
    .multiselect {
      width: 100%;
      min-width: 100%;
    }
  }
</style>
