<template>
  <div v-show="loaded" class="page-block-item">
    <multiselect v-model="selectedRestaurant" :options="restaurantOptions"
        label="label" track-by="label"
        :searchable="true" :allow-empty="false" :close-on-select="true"
        :show-labels="false"
        :placeholder= "getSelectRestaurantLabel()">
    </multiselect>
  </div>
</template>

<script>
import EmployeeApi from '@/api/employee.api';
import Utils from '@/utils/utils';

import Multiselect from 'vue-multiselect';

const CLIENT = Utils.getClient();

function mapToSelect(label, value) {
  return { label, value };
}

export default {
  name: 'SelectableRestaurant',
  components: {
    Multiselect,
  },
  props: ['value', 'userId'],

  data() {
    return {
      loaded: false,
      restaurants: [],
      restaurantOptions: [],
      selectedRestaurant: undefined,
    };
  },

  async created() {
    try {
      const restaurantsResult = await EmployeeApi.getRestaurantList(this.userId);
      this.restaurants = restaurantsResult.data;
      this.restaurantOptions = this.restaurants
        .map((restaurant) => mapToSelect(`${restaurant.name}`, restaurant.id));

      this.$emit('completed', true);
    } catch (e) {
      //
    }
    this.loaded = true;
  },

  methods: {
    getSelectRestaurantLabel() {
      if (CLIENT === 'blazepizza' || CLIENT === 'centerplate') {
        return this.$t('selectLocationLabel');
      }
      if (CLIENT === 'windham') {
        return this.$t('selectDepartmentLabel');
      }
      return this.$t('selectRestaurantLabel');
    },
  },

  watch: {
    selectedRestaurant() {
      this.$emit('input', this.selectedRestaurant);
    },
  },
};
</script>
<style scoped>

</style>
